<template>
  <div>
    <v-snackbar
      v-model="message.snackbar"
      :timeout="message.snackbarTimeout"
      top
    >
      {{ message.snackbarText }}
      <v-btn dark text @click="message.snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-card class="mx-auto" max-width="1000" style="margin-top: -64px">
      <v-toolbar flat>
        <v-toolbar-title class="grey--text">Users</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <div class="flex-grow-1"></div>
        <v-text-field
          class="mt-7"
          v-model="search"
          label="Search"
          required
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-toolbar>

      <v-divider></v-divider>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            @click="newUser"
            top
            absolute
            fab
            dark
            small
            color="blue darken-2"
          >
            <v-icon v-on="on" dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>New user</span>
      </v-tooltip>

      <v-card-text>
        <v-alert type="error" v-model="message.showError">{{
          message.errorMessage
        }}</v-alert>

        <v-dialog v-model="createUserDialog" scrollable max-width="700px">
          <v-card>
            <v-card-title class="grey--text">Create User</v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-form ref="form">
                <v-text-field
                  v-model="form.username"
                  label="Username"
                  persistent-hint
                  hint="Must be email address"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>

                <v-text-field
                  label="New Password"
                  v-model="form.password"
                  :rules="[rules.required, rules.minimum, rules.lowerUpperCase]"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNewPassword ? 'text' : 'password'"
                  @click:append="showNewPassword = !showNewPassword"
                ></v-text-field>

                <v-text-field
                  v-model="form.firstName"
                  label="First name"
                  :rules="[rules.required]"
                ></v-text-field>

                <v-text-field
                  v-model="form.lastName"
                  label="Last name"
                  :rules="[rules.required]"
                ></v-text-field>

                <v-select
                  v-model="selectedrole"
                  :items="roles"
                  attach
                  chips
                  label="Roles"
                  multiple
                ></v-select>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="blue darken-1"
                text
                @click="createUserDialog = false"
                >Close</v-btn
              >
              <v-btn color="blue darken-1" text @click="onUserSubmit"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="createEditDialog" scrollable max-width="700px">
          <v-card>
            <v-card-title class="grey--text">Edit User</v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-form ref="form">
                <v-text-field
                  v-model="form.username"
                  label="Username"
                  persistent-hint
                  hint="Must be email address"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>

                <v-text-field
                  v-model="form.firstName"
                  label="First name"
                  :rules="[rules.required]"
                ></v-text-field>

                <v-text-field
                  v-model="form.lastName"
                  label="Last name"
                  :rules="[rules.required]"
                ></v-text-field>

                <v-select
                  v-model="selectedrole"
                  :items="roles"
                  attach
                  chips
                  label="Roles"
                  multiple
                ></v-select>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                color="blue darken-1"
                text
                @click="createEditDialog = false"
                >Close</v-btn
              >
              <v-btn color="blue darken-1" text @click="onUserSubmit"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deleteUserDialog" max-width="450">
          <v-card>
            <v-card-title class="headline red--text">Delete User?</v-card-title>

            <v-card-text>
              <v-alert text prominent type="error"
                >This action will permanently remove user access to Kubia
                applications.</v-alert
              >
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>

              <v-btn
                color="blue darken-1"
                text
                @click="deleteUserDialog = false"
                >Cancel</v-btn
              >

              <v-btn color="red darken-1" text @click="deleteUser()"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="resetUserDialog" max-width="450">
          <v-card>
            <v-card-title class="headline red--text"
              >Reset User Password?</v-card-title
            >

            <v-card-text>
              <v-alert text prominent type="error"
                >This action will send a verification code to the users email
                address.</v-alert
              >
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>

              <v-btn color="blue darken-1" text @click="resetUserDialog = false"
                >Cancel</v-btn
              >

              <v-btn color="red darken-1" text @click="resetPassword()"
                >Reset</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="disableUserDialog" max-width="450">
          <v-card>
            <v-card-title class="headline red--text"
              >Disable User?</v-card-title
            >

            <v-card-text>
              <v-alert text prominent type="error"
                >This action will disable the user so that he/she will not be
                able to log into system.</v-alert
              >
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>

              <v-btn
                color="blue darken-1"
                text
                @click="disableUserDialog = false"
                >Cancel</v-btn
              >

              <v-btn color="red darken-1" text @click="disableUser()"
                >Disable</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="enableUserDialog" max-width="450">
          <v-card>
            <v-card-title class="headline red--text">Enable User?</v-card-title>

            <v-card-text>
              <v-alert text prominent type="error"
                >This action will enable the user so that he/she WILL be able to
                log into system.</v-alert
              >
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>

              <v-btn
                color="blue darken-1"
                text
                @click="enableUserDialog = false"
                >Cancel</v-btn
              >

              <v-btn color="red darken-1" text @click="enableUser()"
                >Enable</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card flat v-for="user in filteredData" :key="user.email">
          <v-layout wrap :class="`pa-3 project ${user.email}`">
            <v-flex xs12 sm1>
              <v-avatar size="40" v-if="user.s3avatarUrl" class="ml-1">
                <img :src="user.s3avatarUrl" />
              </v-avatar>
              <v-icon size="48" v-if="!user.s3avatarUrl" color="grey lighten-1"
                >mdi-account-circle</v-icon
              >
            </v-flex>
            <v-flex xs12 sm2>
              <div class="caption grey--text">Name</div>
              <div>{{ user.firstName }} {{ user.lastName }}</div>
            </v-flex>
            <v-flex xs12 md3>
              <div class="caption grey--text">Email</div>
              <div>{{ user.email }}</div>
            </v-flex>
            <v-flex xs12 md3>
              <div class="caption grey--text">Status</div>
              <div>
                <span :class="getStatusColor(user.userStatus)">{{
                  user.userStatus
                }}</span>
              </div>
            </v-flex>
            <v-flex xs12 sm3>
              <div class="float-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mx-2" fab dark small color="success">
                      <v-icon v-on="on" @click="editUser(user)" dark
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Edit user</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mx-2" fab dark small color="info">
                      <v-icon
                        v-on="on"
                        @click="onResetUserDialog(user.email)"
                        dark
                        >mdi-lock-reset</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Reset password</span>
                </v-tooltip>
                <v-tooltip bottom v-if="user.enabled">
                  <template v-slot:activator="{ on }">
                    <v-btn class="mx-2" fab dark small color="orange">
                      <v-icon
                        v-on="on"
                        @click="onDisableUserDialog(user.email)"
                        dark
                        >mdi-lock</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Disable user</span>
                </v-tooltip>
                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mx-2" fab dark small color="primary">
                      <v-icon
                        v-on="on"
                        @click="onEnableUserDialog(user.email)"
                        dark
                        >mdi-lock-open</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Enable user</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mx-2" fab dark small color="error">
                      <v-icon
                        v-on="on"
                        @click="onDeleteUserDialog(user.email)"
                        dark
                        >mdi-delete-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Delete user</span>
                </v-tooltip>
              </div>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { Storage } from "aws-amplify";
import { hpUser } from "../classes/hpUser";

export default {
  components: {},
  async created() {
    this.getUsers();
  },
  data() {
    return {
      show: false,
      items: [],

      fields: [
        { key: "username", class: "text-left", sortable: true },
        { key: "firstName", class: "text-left", sortable: true },
        { key: "lastName", class: "text-left", sortable: true },
        { key: "actions", label: "Actions" },
      ],

      userForm: {
        id: "user-form-modal",
      },
      row: {},
      form: {},
      message: {
        showError: false,
        errorMessage: null,
        snackbar: false,
        snackbarText: "",
        snackbarTimeout: 3000,
        show: false,
        message: null,
      },
      filter: null,
      selectedrole: [],
      roles: [],
      showNewPassword: false,
      createUserDialog: false,
      createEditDialog: false,
      deleteUserDialog: false,
      resetUserDialog: false,
      disableUserDialog: false,
      enableUserDialog: false,
      rules: {
        required: (v) => !!v || "This field is required.",
        minimum: (v) => v.length >= 8 || "Minimum 8 characters",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        passwordMatch: () =>
          this.form.newPassword === this.form.confirmNewPassword ||
          "Password must match",
        lowerUpperCase: (v) =>
          /(?=.*[a-z])(?=.*[A-Z])/.test(v) ||
          "Upper and lowercase characters required.",
        tel: (v) =>
          /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/.test(v) ||
          "International format required eg +44",
      },
      search: "",
    };
  },
  methods: {
    resetPassword() {
      let that = this;
      let hpUserObj = new hpUser();
      hpUserObj
        .resetAwsCognitoPassword(that.row.username)
        .then((data) => {
          that.showSuccessMessage(data);
          this.resetUserDialog = false;
        })
        .catch(function (error) {
          that.showErrorMessage(error);
        });
    },
    getAttribute(attributes, key) {
      let value = "";
      for (var i = 0; i < attributes.length; i++) {
        if (attributes[i].Name === key) {
          value = attributes[i].Value;
        }
      }
      return value;
    },
    editUser(user) {
      this.showPassword = false;
      this.row = user;
      this.selectedrole = [];
      (this.form.username = user.email), (this.form.firstName = user.firstName);
      this.form.lastName = user.lastName;
      let that = this;

      let hpUserObj = new hpUser();
      hpUserObj.getUserByUsername(user.username).then((role) => {
        that.selectedrole = role;
        this.createEditDialog = true;
      });
    },
    getUsers() {
      // Get Cognito Users
      const hpUserObj = new hpUser();
      hpUserObj.getUsers().then((users) => {
        this.items = users;
      });

      // List Roles
      hpUserObj.getAwsCognitoGroups().then((roles) => {
        this.roles = roles;
      });
    },
    newUser() {
      this.selectedrole = [];
      this.showPassword = true;
      this.hideMessages();
      this.resetUserForm();
      this.createUserDialog = true;
    },
    onDeleteUserDialog(username) {
      this.row.username = username;
      this.deleteUserDialog = true;
    },
    onResetUserDialog(username) {
      this.row.username = username;
      this.resetUserDialog = true;
    },
    onDisableUserDialog(username) {
      this.row.username = username;
      this.disableUserDialog = true;
    },
    onEnableUserDialog(username) {
      this.row.username = username;
      this.enableUserDialog = true;
    },
    deleteUser(row, index, button) {
      const that = this;
      this.hideMessages();
      const hpUserObj = new hpUser();
      // Delete Cognito User
      hpUserObj
        .deleteAwsCognitoUser(that.row.username)
        .then((data) => {
          that.showSuccessMessage(data);
          that.getUsers();
        })
        .catch(function (error) {
          that.showErrorMessage(error);
        });
      this.deleteUserDialog = false;
    },
    disableUser() {
      const that = this;
      this.hideMessages();
      const hpUserObj = new hpUser();
      // Delete Cognito User
      hpUserObj
        .disableUser(that.row.username)
        .then((data) => {
          that.showSuccessMessage(data);
          that.getUsers();
        })
        .catch(function (error) {
          that.showErrorMessage(error);
        });
      this.disableUserDialog = false;
    },
    enableUser() {
      const that = this;
      this.hideMessages();
      const hpUserObj = new hpUser();
      // Delete Cognito User
      hpUserObj
        .enableUser(that.row.username)
        .then((data) => {
          that.showSuccessMessage(data);
          that.getUsers();
        })
        .catch(function (error) {
          that.showErrorMessage(error);
        });
      this.enableUserDialog = false;
    },
    resetUserForm() {
      this.userForm.content = "";
      this.form = {
        username: "",
      };
    },
    onUserSubmit() {
      if (this.$refs.form.validate()) {
        var that = this;
        this.hideMessages();

        // Get Cognito Users
        const hpUserObj = new hpUser();
        hpUserObj
          .createAwsCognitoUser(this.form)
          .then((data) => {
            that.showSuccessMessage(data);
            that.createUserDialog = false;
            that.createEditDialog = false;
            that.updateUserAttributes();
          })
          .catch(function (error) {
            that.createUserDialog = false;
            that.createEditDialog = false;
            that.showErrorMessage(error);
          });
      }
    },
    updateUserAttributes() {
      let that = this;

      let addRoles = [];
      let removeRoles = [];

      for (var i = 0; i < this.roles.length; i++) {
        var addRole = false;
        for (var c = 0; c < this.selectedrole.length; c++) {
          if (this.selectedrole[c] === that.roles[i].value) {
            addRole = true;
            addRoles.push(that.selectedrole[c]);
          }
        }
        if (addRole === false) {
          removeRoles.push(this.roles[i].value);
        }
      }

      const hpUserObj = new hpUser();
      hpUserObj
        .updateAwsCognitoUserAttributes(addRoles, removeRoles, this.form)
        .then((data) => {
          that.showSuccessMessage(data);
          that.getUsers();
          this.show = false;
        })
        .catch(function (error) {
          that.showErrorMessage(error);
        });
    },
    showErrorMessage(message) {
      this.message = {
        showError: false,
        errorMessage: null,
      };
      this.message.errorMessage = message;
      this.message.showError = true;
    },
    showSuccessMessage(message) {
      this.message.snackbarText = message;
      this.message.snackbar = true;
    },
    hideMessages() {
      // Reset Messages
      this.message = {
        showError: false,
        errorMessage: null,
        snackbar: false, //
        snackbarText: null,
      };
    },
    async getS3Image(key) {
      let promise = await Storage.get(key, { level: "private" });
      let result = await promise;
      return result;
    },
    async getKey(key) {
      return await Storage.get(key, { level: "private" });
    },
    getStatusColor(status) {
      let color;
      switch (status) {
        case "CONFIRMED":
          color = "green--text";
          break;
        case "FORCE_CHANGE_PASSWORD":
          color = "orange--text";
          break;
        default:
          color = "red--text";
          break;
      }
      return color;
    },
  },
  computed: {
    state() {
      return this.form.username.length >= 4 ? true : false;
    },
    invalidFeedback() {
      if (this.form.username.length > 4) {
        return "";
      } else if (this.form.username.length > 0) {
        return "Enter at least 4 characters";
      } else {
        return "Please enter an email address";
      }
    },
    validFeedback() {
      return this.state === true ? "Thank you" : "";
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filteredData() {
      if (this.search) {
        return this.items.filter(
          (item) =>
            (item.firstName &&
              item.firstName.toLowerCase().indexOf(this.search.toLowerCase()) !=
                -1) ||
            (item.lastName &&
              item.lastName.toLowerCase().indexOf(this.search.toLowerCase()) !=
                -1) ||
            (item.email &&
              item.email.toLowerCase().indexOf(this.search.toLowerCase()) !=
                -1) ||
            (item.userStatus &&
              item.userStatus
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) != -1)
        );
      }
      return this.items;
    },
  },
};
</script>
<style>
table {
  font-size: 0.9em;
}
</style>
