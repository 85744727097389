<template>
  <v-container>
    <v-row class="mb-6">
      <v-col>
        <v-card class="mx-auto" style="margin-top: -64px">
          <v-toolbar flat>
            <v-toolbar-title class="grey--text"
              >Chilled Driving Tuition Applications</v-toolbar-title
            >
            <div class="flex-grow-1"></div>
          </v-toolbar>
          <v-divider></v-divider>

          <v-layout>
            <v-flex>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <a
                      href="https://www.chilleddrivingtuition.co.uk/"
                      target="_blank"
                      class="black--text"
                    >
                      Chilled Driving Tuition Website
                    </a>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DefaultCardLayout from "@/layouts/DefaultCardLayout";

export default {
  name: "home",
  components: {},
  async created() {
    this.$emit(`update:layout`, DefaultCardLayout);
  },
};
</script>

<style>
.menuitem {
  float: left !important;
  text-align: left !important;
  color: #6e2e9f !important;
}
</style>
