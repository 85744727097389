<template>
  <div class="userrolesreview">
    <user-roles></user-roles>
  </div>
</template>

<script>
import UserRoles from "@/components/UserRoles.vue";
import DefaultCardLayout from "@/layouts/DefaultCardLayout";

export default {
  name: "userrolesreview",
  components: {
    UserRoles,
  },
  created() {
    this.$emit(`update:layout`, DefaultCardLayout);
  },
};
</script>
