<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" top>
      {{ snackbarText }}
      <v-btn dark text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
    <v-card class="mx-auto" max-width="700" style="margin-top: -64px">
      <v-toolbar flat>
        <v-toolbar-title class="grey--text"
          >Change Your Password</v-toolbar-title
        >
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form">
          <v-alert type="error" v-model="error">
            {{ errorMsg }}
          </v-alert>

          <v-text-field
            label="Current Password"
            v-model="form.oldPassword"
            :rules="[rules.required, rules.min, rules.lowerUpperCase]"
            :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showCurrentPassword ? 'text' : 'password'"
            @click:append="showCurrentPassword = !showCurrentPassword"
          ></v-text-field>
          <v-text-field
            label="New Password"
            v-model="form.newPassword"
            :rules="[rules.required, rules.min, rules.lowerUpperCase]"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showNewPassword ? 'text' : 'password'"
            @click:append="showNewPassword = !showNewPassword"
          ></v-text-field>
          <v-text-field
            label="Confirm New Password"
            v-model="form.confirmNewPassword"
            :rules="[
              rules.required,
              rules.min,
              rules.passwordMatch,
              rules.lowerUpperCase,
            ]"
            :append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmNewPassword ? 'text' : 'password'"
            @click:append="showConfirmNewPassword = !showConfirmNewPassword"
          ></v-text-field>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn @click="onChangePassword" color="info"
              >Change password</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
import DefaultCardLayout from "@/layouts/DefaultCardLayout";
export default {
  created() {
    this.$emit(`update:layout`, DefaultCardLayout);
  },
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      rules: {
        required: (v) => !!v || "This field is required.",
        min: (v) => v.length >= 8 || "Minimum 8 characters",
        passwordMatch: () =>
          this.form.newPassword === this.form.confirmNewPassword ||
          "Password must match",
        lowerUpperCase: (v) =>
          /(?=.*[a-z])(?=.*[A-Z])/.test(v) ||
          "Upper and lowercase characters required.",
      },
      snackbar: false,
      snackbarText: "",
      snackbarTimeout: 3000,

      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      error: false,
      errorMsg: "",
    };
  },
  methods: {
    onChangePassword() {
      this.snackbar = false;
      this.error = false;

      if (this.$refs.form.validate()) {
        Auth.currentAuthenticatedUser()
          .then((user) => {
            return Auth.changePassword(
              user,
              this.form.oldPassword,
              this.form.newPassword
            );
          })
          .then((data) => {
            if (data === "SUCCESS") {
              this.snackbarText = "Password successfully changed";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            console.log(err.message);
            this.errorMsg = err.message;
            this.error = true;
          });
      }
    },
  },
};
</script>

<style>
small {
  text-align: left;
}
</style>