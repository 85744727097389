<template>
  <div>
    <!-- <v-alert v-if="showMessage" outlined type="success" color="green" text id="forgotPasswordMessage">
      {{ message }}
    </v-alert> -->
    <amplify-forgot-password
      slot="forgot-password"
      secondary-footer-content=""
    ></amplify-forgot-password>
  </div>
</template>

<script>
import { Hub } from "aws-amplify";
export default {
  created() {
    Hub.listen("auth", async (state) => {
      // this.message = null;
      // this.showMessage = false;
      if (state.payload.event === "forgotPasswordSubmit") {
        this.$router.push("/").catch((err) => {});
      }
    });
  },
};
</script>
<style scoped>
/* #forgotPasswordMessage {
  width: 460px;
} */
</style>