<template>
  <v-app>
    <v-speed-dial
      style="position: absolute; z-index: 2; right: 20px; top: 10px"
      v-model="fab"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark fab small>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="green" to="/profile">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn v-if="isAdminRole" fab dark small color="indigo" to="/users">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn fab dark small color="red" @click="onSignOut()">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-card flat>
      <v-toolbar color="transparent" extended extension-height="110px" flat>
        <v-toolbar-title style="z-index: 1" class="mt-2">
          <v-img
            :src="require('../assets/logo.png')"
            contain
            height="80"
            position="left"
          ></v-img>
        </v-toolbar-title>
        <v-spacer style="z-index: 1"></v-spacer>
        <v-toolbar-items style="z-index: 1">
          <v-btn text router to="/" color="black">Home</v-btn>
          <v-btn v-if="isAdminRole" text router to="/users" color="black"
            >Users</v-btn
          >
          <v-btn v-if="isAdminRole" text router to="/user-roles"
            >User roles</v-btn
          >
          <v-btn text router to="/profile" color="black">Profile</v-btn>
          <v-btn
            text
            router
            to="/changepassword"
            style="margin-right: 55px"
            color="black"
            >Change Password</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
      <slot />
    </v-card>
  </v-app>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  methods: {
    async onSignOut() {
      await this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    setRoles() {
      Auth.currentAuthenticatedUser().then((user) => {
        const awsGroups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        if (awsGroups) {
          this.roles =
            user.signInUserSession.accessToken.payload["cognito:groups"];
        }
      });
    },
  },
  created() {
    this.setRoles();
  },
  data: () => ({
    direction: "bottom",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    transition: "slide-y-reverse-transition",
    roles: [],
  }),
  computed: {
    isAdminRole() {
      return this.roles.includes("SUPER-ADMIN");
    },
  },
};
</script>
