/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:346b57db-d8cc-4c73-9a0b-96abdf129bfa",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_D1V7FmH7N",
    "aws_user_pools_web_client_id": "1f7u96ru3qfdnmfjmerpd31jgp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "chilledDrivingUserApi",
            "endpoint": "https://hh5xseob5m.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
