import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Amplify from "aws-amplify";
import vuetify from "./plugins/vuetify";
import awsconfig from "./aws-exports";
import DefaultLayout from "./layouts/DefaultCardLayout";
import FullPageLayout from "./layouts/FullPageLayout";

import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";

Amplify.configure(awsconfig);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

Amplify.configure(awsconfig);

Vue.config.productionTip = false;
Vue.component("DefaultLayout", DefaultLayout);
Vue.component("FullPageLayout", FullPageLayout);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// Let's style this up
require("./assets/style.css");
