<template>
  <div>
    <v-card class="mx-auto" max-width="1000" style="margin-top: -64px">
      <v-toolbar flat>
        <v-toolbar-title class="grey--text">User roles review</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <div class="flex-grow-1"></div>
        <v-text-field
          class="mt-7"
          v-model="search"
          label="Search"
          required
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-toolbar>

      <v-divider></v-divider>

      <v-dialog v-model="showRoleChangeDialog" scrollable max-width="700px">
        <v-card>
          <v-card-title class="grey--text">Edit roles</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" v-if="roleChangeUser !== null">
              <v-text-field
                label="Username"
                readonly
                :value="'' + roleChangeUser.email"
              ></v-text-field>

              <v-text-field
                label="First name"
                readonly
                :value="'' + roleChangeUser.firstName"
              ></v-text-field>

              <v-text-field
                label="Last name"
                readonly
                :value="'' + roleChangeUser.lastName"
              ></v-text-field>

              <v-select
                v-model="roleChangeRoles"
                :items="roles"
                attach
                chips
                label="Roles"
                multiple
              ></v-select>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="showRoleChangeDialog = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveRoleChanges"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-simple-table dense class="table-striped" fixed-header height="600px">
          <template v-slot:default>
            <thead>
              <tr>
                <th scope="col" class="regularText">Email / Name</th>
                <th
                  scope="col"
                  v-for="role in roles"
                  :key="role.username"
                  class="verticalText slimPadding"
                >
                  <v-tooltip bottom v-if="role.description.length > 0">
                    <template v-slot:activator="{ on }">
                      <span class="text" v-on="on">{{
                        prettyRoleName(role.text)
                      }}</span>
                    </template>
                    <span>{{ role.description }}</span>
                  </v-tooltip>
                  <span class="text" v-else>{{
                    prettyRoleName(role.text)
                  }}</span>
                </th>
                <th scope="col" class="verticalText slimPadding">
                  <span class="text">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(user, index) in filteredDataSortedAsc"
                :class="{ active: index % 2 !== 0 }"
              >
                <td :class="{ userDisabled: !user.enabled }">
                  <strong>{{ user.firstName }} {{ user.lastName }}</strong
                  ><br /><small>({{ user.email }})</small>
                </td>
                <td v-for="role in roles" class="slimPadding">
                  <v-icon
                    v-if="userHasRole(user, role.value)"
                    color="green"
                    large
                    >mdi-check-circle-outline</v-icon
                  >
                  <v-icon v-else color="#FF000022" large
                    >mdi-close-circle-outline</v-icon
                  >
                </td>
                <td class="slimPadding">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn fab dark x-small color="success">
                        <v-icon v-on="on" dark @click="editUserRoles(user)"
                          >mdi-pencil</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Edit roles</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import { hpUser } from "../classes/hpUser";

export default {
  components: {},
  async created() {
    this.getUsers();
  },
  data() {
    return {
      showRoleChangeDialog: false,
      roleChangeUser: null,
      roleChangeRoles: [],
      items: [],
      roles: [],
      message: {
        showError: false,
        errorMessage: null,
        snackbar: false,
        snackbarText: "",
        snackbarTimeout: 3000,
        show: false,
        message: null,
      },
      search: "",
    };
  },
  methods: {
    prettyRoleName(role) {
      return role.replace("_", " ").toLowerCase();
    },

    userHasRole(user, role) {
      return user.roles.includes(role);
    },

    getUsers() {
      // Get Cognito Users
      const hpUserObj = new hpUser();
      hpUserObj.getUsers().then((users) => {
        this.items = users;

        // Get user roles.
        for (let userIndex = 0; userIndex < this.items.length; userIndex++) {
          Vue.set(this.items[userIndex], "roles", []);
          const hpUserRoleObj = new hpUser();
          hpUserRoleObj
            .getUserByUsername(this.items[userIndex].username)
            .then((role) => {
              Vue.set(this.items[userIndex], "roles", role);
            });
        }
      });

      // List Roles
      hpUserObj.getAwsCognitoGroups().then((roles) => {
        this.roles = roles;
      });
    },

    editUserRoles(user) {
      this.roleChangeUser = user;
      this.roleChangeRoles = [...user.roles];
      this.showRoleChangeDialog = true;
    },

    saveRoleChanges() {
      let that = this;
      that.hideMessages();

      let rolesToAdd = [];
      let rolesToRemove = [];
      for (
        let selectedRoleIndex = 0;
        selectedRoleIndex < this.roleChangeRoles.length;
        selectedRoleIndex++
      ) {
        if (
          !this.roleChangeUser.roles.includes(
            this.roleChangeRoles[selectedRoleIndex]
          )
        ) {
          rolesToAdd.push(this.roleChangeRoles[selectedRoleIndex]);
        }
      }
      for (
        let existingRoleIndex = 0;
        existingRoleIndex < this.roleChangeUser.roles.length;
        existingRoleIndex++
      ) {
        if (
          !this.roleChangeRoles.includes(
            this.roleChangeUser.roles[existingRoleIndex]
          )
        ) {
          rolesToRemove.push(this.roleChangeUser.roles[existingRoleIndex]);
        }
      }

      const hpUserObj = new hpUser();
      hpUserObj
        .updateAwsCognitoUserRoles(
          rolesToAdd,
          rolesToRemove,
          this.roleChangeUser.username
        )
        .then((data) => {
          that.showSuccessMessage(data);
          this.showRoleChangeDialog = false;
          that.getUsers();
        })
        .catch(function (error) {
          that.showErrorMessage(error);
        });
    },
    showErrorMessage(message) {
      this.message = {
        showError: false,
        errorMessage: null,
      };
      this.message.errorMessage = message;
      this.message.showError = true;
    },
    showSuccessMessage(message) {
      this.message.snackbarText = message;
      this.message.snackbar = true;
    },
    hideMessages() {
      // Reset Messages
      this.message = {
        showError: false,
        errorMessage: null,
        snackbar: false, //
        snackbarText: null,
      };
    },
  },
  computed: {
    filteredData() {
      if (this.search && this.search.length >= 1) {
        return this.items.filter(
          (item) =>
            (item.firstName &&
              item.firstName.toLowerCase().indexOf(this.search.toLowerCase()) !=
                -1) ||
            (item.lastName &&
              item.lastName.toLowerCase().indexOf(this.search.toLowerCase()) !=
                -1) ||
            (item.phoneNumber &&
              item.phoneNumber
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) != -1) ||
            (item.username &&
              item.username.toLowerCase().indexOf(this.search.toLowerCase()) !=
                -1)
        );
      }
      return this.items;
    },
    filteredDataSortedAsc() {
      return [...this.filteredData].sort((a, b) =>
        a.firstName + " " + a.lastName > b.firstName + " " + b.lastName ? 1 : -1
      );
    },
  },
};
</script>
<style>
table {
  font-size: 0.9em;
}

.regularText {
  padding-bottom: 10px !important;
  vertical-align: bottom;
}

.verticalText {
  text-transform: capitalize;
  line-height: 100%;
  vertical-align: bottom;
  text-align: center !important;
  padding-bottom: 10px !important;
}

.verticalText .text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  /*white-space: nowrap;*/

  transform: rotate(-40deg);
  -webkit-transform: rotate(-40deg);
  -moz-transform: rotate(-40deg);
  -ms-transform: rotate(-40deg);
  -o-transform: rotate(-40deg);
}

.slimPadding {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.userDisabled {
  color: #a0a0a0;
  text-decoration: line-through;
}
</style>
