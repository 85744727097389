<template>
  <div class="users">
    <Users></Users>
  </div>
</template>

<script>
import Users from "@/components/Users.vue";
import DefaultCardLayout from "@/layouts/DefaultCardLayout";

export default {
  name: "users",
  components: {
    Users
  },
  created() {
    this.$emit(`update:layout`, DefaultCardLayout);
  }
};
</script>